#app-body {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
  padding-top: $navbar-height;
}

.pusher {
  margin-left: 15rem;
  min-height: 100%;
  overflow: auto;
  flex-grow: 1;

  @include respond-below(sm) {
    margin-left: 0;
  }
}

.container-wrapper {
  position: relative !important;
}

.scrolling-wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  position: absolute !important;
  bottom: 8px;
  left: 16px;
  margin-right: 70px;
  column-count: 3;

  @include respond-below(sm) {
    margin-right: 60px;

    .ui.card {
      width: 270px;
    }
  }
}

.scroll-horizontal {
  display: flex;
  grid-column-start: 2;
  padding: 10px 0 10px 0!important;
  overflow-x: auto !important;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none !important;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  :not(:last-child) {
    margin-right: 10px;
  }

  :last-child {
    margin-right: 2px;
  }

  :first-child {
    margin-left: 2px;
  }
}

.ui.segment {
  margin: 0;
}

#details-page {
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-flow: column;
  top: 0;
  width: 100%;
  background-color: $white;
}

#close-header {
  flex-grow: 1;
  width: 100%;
  height: auto;
}

#comments-container {
  margin: 50px auto;
}

.item-img.full {
  max-width: 100%;
}