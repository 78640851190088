.ui.segment.center-comment {
  width: 60%;
  display: block;
  margin: 50px auto;
  border-radius: 0 30px;
  box-shadow: none;
  -webkit-box-shadow: none;

  @include respond-below(lg) {
    width: 70%;
  }

  @include respond-below(sm) {
    width: 90%;
  }
}

.repositories{
  min-height: calc(100vh - 45px);
}